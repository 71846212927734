import { useRef, useState } from "react"
import styled from "styled-components"

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  size,
  useFloating,
  useInteractions,
  useListNavigation,
} from "@floating-ui/react"
import { useDismiss, useRole } from "@floating-ui/react"
import { useDebounceCallback } from "usehooks-ts"

import { cardBoxShadow } from "src/constants/shadows"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { mColors } from "src/ui/colors"
import ChevronDown from "src/ui/icons/chevron-down-larger.svg"
import XIcon from "src/ui/icons/x.svg"
import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TOmittedInputContainerProps = Omit<
  TInputContainerProps,
  | "shrink"
  | "tabIndex"
  | "endAdornment"
  | "cursor"
  | "children"
  | "showClearButton"
  | "onClear"
>

export type TComboboxOption = {
  label: string
  value: string
  description?: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  selectedLabelText?: string
  disabled?: boolean
}

type TBaseComboboxProps = {
  options: TComboboxOption[]
  popoverMaxHeight?: string
  onSearch: (input: string) => void
  required?: boolean
  searchDelay?: number
  initialInput?: string
} & TOmittedInputContainerProps

type TComboboxProps = TBaseComboboxProps &
  (
    | {
        multiple?: false
        selectedValue: string
        onChange: (value: string) => void
      }
    | {
        multiple: true
        selectedValue: { label: string; value: string }[]
        onChange: (value: { label: string; value: string }[]) => void
      }
  )

export function Combobox({
  label,
  startAdornment,
  selectedValue,
  options,
  popoverMaxHeight,
  onChange,
  onSearch,
  required,
  searchDelay = 500,
  initialInput,
  multiple,
}: TComboboxProps) {
  const [open, setOpen] = useState(false)
  // This is the current virtually focused/hovered item in the list
  const [activeIndex, setActiveIndex] = useState(0)
  const [input, setInput] = useState(initialInput ?? "")

  const selectedIndex = options.findIndex(
    (option) => option.value === selectedValue
  )

  const listRef = useRef<HTMLElement[]>([])

  const debouncedSearch = useDebounceCallback((value: string) => {
    onSearch(value)
  }, searchDelay)

  const floating = useFloating<HTMLDivElement>({
    open,
    // This handles only open changed events triggered by floating-ui
    onOpenChange: (open) => {
      if (open) {
        setActiveIndex(selectedIndex)
      } else {
        if (!selectedValue) {
          setInput("")
        }

        onSearch("")
      }

      setOpen(open)
    },
    middleware: [
      offset(8),
      flip(),
      shift(),
      size({
        apply: ({ rects, elements }) => {
          elements.floating.style.width = `${rects.reference.width}px`
        },
      }),
    ],
    transform: true,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(floating.context)
  const role = useRole(floating.context, {
    role: "combobox",
  })

  const listNav = useListNavigation(floating.context, {
    activeIndex,
    selectedIndex,
    listRef,
    onNavigate: (index) => {
      if (index !== null) {
        setActiveIndex(index)
      }
    },
    virtual: true,
    loop: true,
    scrollItemIntoView: true,
  })

  const interactions = useInteractions([dismiss, role, listNav])

  function handleSelect() {
    const selectedOption = options[activeIndex]

    if (!selectedOption) return

    if (multiple) {
      if (selectedValue.find((s) => s.value === selectedOption.value)) {
        onChange(selectedValue.filter((s) => s.value !== selectedOption.value))
      } else {
        onChange([
          ...selectedValue,
          {
            label: selectedOption.label,
            value: selectedOption.value,
          },
        ])
      }
    } else {
      onChange(selectedOption.value)
      setInput(selectedOption.selectedLabelText ?? selectedOption.label)
    }

    setOpen(false)
  }

  function removeSelection(value: string) {
    if (!multiple) return

    onChange(selectedValue.filter((s) => s.value !== value))
  }

  return (
    <div>
      <div
        ref={floating.refs.setReference}
        {...interactions.getReferenceProps()}
      >
        <InputContainer
          label={label}
          startAdornment={startAdornment}
          endAdornment={<ChevronDown width={16} color="unset" />}
          shrink={multiple ? selectedValue.length > 0 : undefined}
        >
          <InputWrapper $marginTop={!!multiple}>
            {multiple &&
              selectedValue.map((s) => (
                <StyledPill key={s.value}>
                  <PillLabel variant="bodyS">{s.label}</PillLabel>
                  <PillButton
                    onClick={() => {
                      removeSelection(s.value)
                    }}
                  >
                    <XIcon width={10} />
                  </PillButton>
                </StyledPill>
              ))}
            <StyledInput
              type="text"
              value={input}
              aria-label={label}
              required={required}
              onChange={(e) => {
                const value = e.target.value

                setActiveIndex(0)
                setInput(value)
                debouncedSearch(value)
                if (!multiple) {
                  onChange("")
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab" || e.shiftKey) {
                  return
                }

                if (e.key === "Enter") {
                  e.preventDefault()
                  handleSelect()
                } else {
                  setOpen(true)
                }

                if (
                  !(e.target as HTMLInputElement).value &&
                  e.key === "Backspace" &&
                  multiple
                ) {
                  e.preventDefault()
                  removeSelection(
                    selectedValue[selectedValue.length - 1]?.value ?? ""
                  )
                }
              }}
              onClick={() => {
                setOpen((prev) => !prev)
              }}
              onBlur={(e) => {
                // Due to us having to set `closeOnFocusOut={false}` the automatic close on focusout is disabled and we have to handle it here instead
                if (
                  !floating.refs.floating.current?.contains(e.relatedTarget)
                ) {
                  setOpen(false)
                }
              }}
            />
          </InputWrapper>
        </InputContainer>
      </div>
      <FloatingPortal>
        {open && options.length > 0 && (
          <FloatingFocusManager
            context={floating.context}
            initialFocus={-1}
            modal={false}
            /* 
              When we let floating-ui handle focusout events, it does not play well with the MUI dialog. The browser gives the dialog the focus when you click an item in the listbox.
              We can hopefully give back control when we have our own dialog component.
            */
            closeOnFocusOut={false}
          >
            <PopoverContent
              ref={floating.refs.setFloating}
              style={floating.floatingStyles}
              {...interactions.getFloatingProps()}
              $maxHeight={popoverMaxHeight}
            >
              <div>
                {options.map((option, index) => (
                  <ComboboxItem
                    key={option.value}
                    {...interactions.getItemProps({
                      ref: (node) => {
                        if (listRef.current && node) {
                          listRef.current[index] = node
                        }
                      },
                      onClick: !option.disabled ? handleSelect : undefined,
                      active: index === activeIndex,
                      selected: multiple
                        ? !!selectedValue.find((s) => s.value === option.value)
                        : option.value === selectedValue,
                    })}
                    $active={index === activeIndex}
                    $selected={
                      multiple
                        ? !!selectedValue.find((s) => s.value === option.value)
                        : option.value === selectedValue
                    }
                    $disabled={option.disabled}
                    aria-disabled={option.disabled}
                  >
                    {option.icon && (
                      <div>
                        <option.icon width={24} />
                      </div>
                    )}
                    <div>
                      {option.label}
                      {option.description && (
                        <MText variant="bodyS" color="secondary">
                          {option.description}
                        </MText>
                      )}
                    </div>
                  </ComboboxItem>
                ))}
              </div>
            </PopoverContent>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  )
}

const ComboboxItem = styled.div<{
  $active: boolean
  $selected: boolean
  $disabled?: boolean
}>`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;

  cursor: ${({ $disabled }) => ($disabled ? "auto" : "pointer")};
  padding: ${spacing.M};
  background-color: ${({ $active, $selected, $disabled }) => {
    if ($selected) {
      return mColors.neutralDark
    }

    if ($active && !$disabled) {
      return mColors.neutral
    }

    return mColors.neutralLight
  }};

  color: ${({ $disabled }) => {
    if ($disabled) {
      return mColors.textInactive
    }

    return mColors.textPrimary
  }};
`

const PopoverContent = styled.div<{ $maxHeight?: string }>`
  max-height: ${({ $maxHeight }) => (!$maxHeight ? "200px" : $maxHeight)};
  overflow-y: auto;
  background-color: ${mColors.neutralLight};
  border: 1px solid ${mColors.divider};
  border-radius: 0.5rem;
  /*
    MUI creates a stacking context for it's floating label which makes the popover render behind it, this makes sure the popover stacking context renders above
    1300 is the z-index of the MUI dialog
  */
  z-index: ${Z_INDEX_ABOVE_MODAL};
  ${cardBoxShadow}
`

const InputWrapper = styled.div<{ $marginTop: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XS};

  margin-top: ${({ $marginTop }) => ($marginTop ? spacing.XS2 : 0)};
`

const StyledInput = styled.input`
  all: unset;
  flex: 1;
`

const StyledPill = styled.div`
  display: flex;
  align-items: center;
  background: ${mColors.systemInfoLight};
  color: ${mColors.textPrimary};
  border-radius: 9999px;
  overflow: hidden;
`

const PillLabel = styled(MText)`
  padding-block: ${spacing.XS3};
  padding-left: ${spacing.S};
  padding-right: ${spacing.XS2};
`

const PillButton = styled.div`
  all: unset;
  padding-right: ${spacing.XS};
  padding-left: ${spacing.XS};
  padding-block: ${spacing.XS3};
  pointer-events: auto;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${mColors.systemError};
  }
`
